export const module = {
  info: {
    fullName: 'Storage-File',
    namespace: 'Storage',
    name: 'File',
    caption: 'File',
    routeName: 'module->Storage->File',
    // viewGroup: 'general-modules'
  },
  vuexModules: ['api'],
  mainRoute: '/cpanel',
  // noRoutes: true,
  sideMenu: {
    notAllowedProfiles: [],
    topSeparator: false,
    caption: 'File',
    icon: 'el-icon-folder',
    routeName: 'list->page',
    routeParams: { listPage: 1}
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  }
}

export default module
